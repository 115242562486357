//#region 接口
import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider'
import address from '@/web3/nft/address.json'
import market from '@/web3/nft/market.json'
import token from '@/web3/nft/token.json'
import nft from '@/web3/nft/nft.json'
import { Toast } from 'vant'
import { reactive, ref } from 'vue'
const authFeeLimit = ref(
  '0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
)
let marketAbi = reactive({})
let nftAbi = reactive({})
let tokenAbi = reactive({})
let metaAddress = window.ethereum.selectedAddress
async function initContrac() {
  const Provider = await reactive(detectEthereumProvider())
  const web3 = reactive(new Web3(Provider))
  marketAbi = new web3.eth.Contract(market, address.market)
  nftAbi = new web3.eth.Contract(nft, address.nft)
  tokenAbi = new web3.eth.Contract(token, address.token)
}
initContrac()
//查询abi
export const abi = () => {
  return marketAbi.methods
}

//1创建项目
let parmas = {
  id: 20000,
  name: '五一节',
  image: 'xx1231',
  describe: '1563',
  time1: 0,
  time2: 2650445203,
}
export const setProject = () => {
  return marketAbi.methods
    ._setProject([
      parmas.id,
      parmas.name,
      parmas.image,
      parmas.time1,
      parmas.time2,
    ])
    .send({
      from: metaAddress,
    })
}

//2创建nft种类
export const setCatNft = () => {
  return marketAbi.methods.setCatNFT().send({
    from: metaAddress,
  })
}

//4购买发行的nft
export const buyNft = (id, currencyAddress, nftAddress, sizi) => {
  return marketAbi.methods.buyNft(id, currencyAddress, nftAddress, sizi).send({
    from: metaAddress,
  })
}

//5售卖
export const startSales = (nftId, price) => {
  return marketAbi.methods
    .startSales(nftId, price, price, address.nft, address.token)
    .send({
      from: metaAddress,
    })
}

//6返还nft到用户
export const cancelSales = (transactionId) => {
  return marketAbi.methods.cancelSales(transactionId).send({
    from: metaAddress,
  })
}

//7 购买用户出售的nft
export const buy = (transactionId) => {
  return marketAbi.methods.buy(transactionId).send({
    from: metaAddress,
  })
}

//8 授权nft合约买卖
export const addSupportNft = () => {
  return marketAbi.methods.addSupportNft(address.nft).send({
    from: metaAddress,
  })
}
// 查询tokenabi
export const tokenabi = () => {
  return tokenAbi.methods
}
//查询余额
export const balanceOf = () => {
  return tokenAbi.methods.balanceOf(metaAddress).call()
}
//查询精度
export const precision = () => {
  return tokenAbi.methods.decimals().call()
}
//授权货币,直接调用即可
export const approve = async () => {
  let result = await balanceOf()
  return tokenAbi.methods
    .approve(address.market, result)
    .send({ from: metaAddress })
}
//授权商品买卖
export const approveTokenid = async (tokenid) => {
  return nftAbi.methods
    .approve(address.market, tokenid)
    .send({ from: metaAddress })
}
//查询是否授权，非0为授权
export let allowance = () => {
  return tokenAbi.methods.allowance(metaAddress, address.token).call()
}
//查询catid
export let getCatId = (tokenid) => {
  return nftAbi.methods.getCatId(tokenid).call()
}
//3获取卡片具体信息tokenid
export const getCatMetadata = async (tokenid) => {
  let result = await getCatId(tokenid)
  return marketAbi.methods.getCatMetadata(result).call()
}
//5获取卡片具体信息catid
export const catMetadata = async (catid) => {
  return marketAbi.methods.getCatMetadata(catid).call()
}

//#endregion
//#region 查询信息
import fetch from 'cross-fetch'
import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client'
// const APIURL = 'http://43.156.81.140:8000/subgraphs/name/p2enfttest'
const APIURL = 'https://api.thegraph.com/subgraphs/name/dcfsdcc/knt-graph'
// 1查询售卖的nft
export let ownerNFTs = () => {
  const tokensQuery = `{ownerNFTs(first: 1000, where: {})
    {
       id
       tokenID  
       nftAddress  
       owner 
       createTime 
       status  
    }
 }`
  const client = new ApolloClient({
    link: new HttpLink({
      uri: APIURL,
      fetch,
    }),
    cache: new InMemoryCache(),
  })
  return client.query({
    query: gql(tokensQuery),
  })
}
// 1.2查询个人拥有和售卖的nft
export let buffNFTs = (skip, first) => {
  const tokensQuery = `{ownerNFTs (skip:${skip},first:${first}, where: {owner:"${metaAddress}"})
    {
       id
       tokenID  
       nftAddress  
       owner 
       createTime 
       status  
    }
 }`
  const client = new ApolloClient({
    link: new HttpLink({
      uri: APIURL,
      fetch,
    }),
    cache: new InMemoryCache(),
  })
  return client.query({
    query: gql(tokensQuery),
  })
}

// 2创建的项目
export let nftprojectDtos = () => {
  const tokensQuery = `{nftprojectDtos (first: 10, where: {})
    {
        id  
        name  
        image 
        projectId 
        startTime 
        endTime  
    }
 }`
  const client = new ApolloClient({
    link: new HttpLink({
      uri: APIURL,
      fetch,
    }),
    cache: new InMemoryCache(),
  })

  return client.query({
    query: gql(tokensQuery),
  })
}

// 3nft种类
export let catagories = (skip, first) => {
  const tokensQuery = `{catagories  (skip:${skip},first:${first}, where: {})
    {
        id
        name
        image
        projectId  
        catId  
        number 
        power 
        creater 
        currencies{
          id
        }
    }
 }`
  const client = new ApolloClient({
    link: new HttpLink({
      uri: APIURL,
      fetch,
    }),
    cache: new InMemoryCache(),
  })

  return client.query({
    query: gql(tokensQuery),
  })
}

// 4币种对应价格
export let currencyEntities = () => {
  const tokensQuery = `{currencyEntities  (first: 1000, where: {})
    {
        id
        token
        amount
    }
 }`
  const client = new ApolloClient({
    link: new HttpLink({
      uri: APIURL,
      fetch,
    }),
    cache: new InMemoryCache(),
  })

  return client.query({
    query: gql(tokensQuery),
  })
}

// 5 用户售卖nft对象
export let nftSaleEntities = (skip, first) => {
  const tokensQuery = `{nftSaleEntities(skip:${skip},first:${first}, where: {})
    {
        id
        saleId
        tokenId 
        seller   
        nftAddress 
        currency 
        price 
        createTime
        status  
    }
 }`
  const client = new ApolloClient({
    link: new HttpLink({
      uri: APIURL,
      fetch,
    }),
    cache: new InMemoryCache(),
  })

  return client.query({
    query: gql(tokensQuery),
  })
}

// 6 合约交易记录
export let transEntities = () => {
  const tokensQuery = `{transEntities (first: 1000, where: {})
    {
    id
    hash
    from 
    to 
    nft: 
    tokenId 
    transferTime
    }
 }`
  const client = new ApolloClient({
    link: new HttpLink({
      uri: APIURL,
      fetch,
    }),
    cache: new InMemoryCache(),
  })

  return client.query({
    query: gql(tokensQuery),
  })
}

//#endregion

//链接钱包
export let linkPurse = () => {
  if (window.ethereum === undefined || window.ethereum.isMetaMask === false) {
  } else {
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then((res) => {})
      .catch((err) => {
        Toast.fail('MetaMask已连接')
      })
  }
}
