export default function (link) {
  if (link !== null) {
    let q = link.substr(0, 1)
    if (q == 'Q') {
      return `https://infura-ipfs.io/ipfs/${link}`
    } else if (q == 'h') {
      return link
    }
    return ''
  }
}
